(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/observability-web/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/core/observability-web/assets/javascripts/utils.js');
"use strict";


(function (_svs$core$observabili, _svs$core$observabili2) {
  'use strict';

  const host = (_svs$core$observabili = svs.core.observability) === null || _svs$core$observabili === void 0 || (_svs$core$observabili = _svs$core$observabili.data) === null || _svs$core$observabili === void 0 ? void 0 : _svs$core$observabili.host;
  const suppress = host === undefined || ((_svs$core$observabili2 = svs.core.observability) === null || _svs$core$observabili2 === void 0 || (_svs$core$observabili2 = _svs$core$observabili2.data) === null || _svs$core$observabili2 === void 0 ? void 0 : _svs$core$observabili2.disabled) === true;
  const {
    trace,
    context,
    defaultTextMapGetter,
    defaultTextMapSetter,
    SpanKind,
    SpanStatusCode
  } = window.OpenTelemetry.api;
  const {
    WebTracerProvider
  } = window.OpenTelemetry.sdkTraceWeb;
  const {
    AlwaysOnSampler,
    BatchSpanProcessor
  } = window.OpenTelemetry.sdkTraceBase;
  const {
    OTLPTraceExporter
  } = window.OpenTelemetry.exporterTraceOtlpHttp;
  const {
    W3CTraceContextPropagator
  } = window.OpenTelemetry.core;
  const {
    Resource
  } = window.OpenTelemetry.resources;
  const {
    ZoneContextManager
  } = window.OpenTelemetry.contextZone;
  const provider = new WebTracerProvider({
    sampler: new AlwaysOnSampler(),
    resource: new Resource({
      'service.name': "".concat(svs.core.config.data.svsconfig.site, "_").concat(svs.core.config.data.svsconfig.tribe),
      'service.version': svs.core.config.data.svsconfig.ver,
      'deployment.environment': svs.core.observability.data.environment
    })
  });
  if (!suppress) {
    const endpoint = host.endsWith('v1/traces') ? host : "".concat(host, "/v1/traces");
    const exporter = new OTLPTraceExporter({
      url: endpoint,
      httpAgentOptions: {
        keepAlive: true,
        timeout: 1000
      }
    });
    provider.addSpanProcessor(new BatchSpanProcessor(exporter, svs.core.observability.batchspanconfig));
  }
  const propagator = new W3CTraceContextPropagator();
  const contextManager = new ZoneContextManager();
  provider.register({
    propagator,
    contextManager
  });
  const inject = (carrier, span) => {
    const ctx = trace.setSpan(context.active(), span);
    propagator.inject(ctx, carrier, defaultTextMapSetter);
    return carrier;
  };
  const extract = carrier => {
    const ctx = propagator.extract(context.active(), carrier, defaultTextMapGetter);
    return ctx;
  };
  svs.core.observability.provider = provider;
  svs.core.observability.context = context;
  svs.core.observability.trace = trace;
  svs.core.observability.inject = inject;
  svs.core.observability.extract = extract;
  svs.core.observability.SpanKind = SpanKind;
  svs.core.observability.SpanStatusCode = SpanStatusCode;
})();

 })(window);