(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/observability-web/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/core/observability-web/assets/javascripts/prescript.js');
"use strict";


svs = svs || {};
svs.core = svs.core || {};
svs.core.observability = svs.core.observability || {};
svs.core.observability.batchspanconfig = svs.core.observability.batchspanconfig || {
  maxExportBatchSize: 512,
  scheduledDelayMillis: 5000,
  exportTimeoutMillis: 30000,
  maxQueueSize: 2048
};

 })(window);